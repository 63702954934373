import * as React from "react"
// import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import SchemaOrg from "./schemaOrg";
// import { useStaticQuery, graphql } from "gatsby"

const Seo = ({description, lang, meta, title, imgSrc, imgCaption, currentURL, homeURL, canonicalURL, ogTitle, keywords}) => {
  

  const metaDescription = description;
  homeURL = "https://laboratorioclinico.sv"

  return (
    <>
    
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        // titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name:`keywords`,
            content: keywords.toString()
          },
          {
            property: `og:locale`,
            content: `es_SV`
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:title`,
            content: ogTitle
          },
          {
            property: `og:url`,
            content: currentURL
          },
          {
            property: `og:site_name`,
            content: `Laboratorio G&S`
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: `${homeURL}${imgSrc}`
          },
          {
            property: `og:image:width`,
            content: `600`
          },
          {
            property: `og:image:height`,
            content: `315`
          },
          {
            property: `og:image:type`,
            content: `image/png`
          }
          
        ].concat(meta)}

        link={canonicalURL&&[
          {
            rel: "canonical",
            href: canonicalURL
          }
        ]}
      />     
      <SchemaOrg 
          homeURL={homeURL} 
          currentURL={currentURL} 
          titlePage={title} 
          pageImage={imgSrc} 
          imgCaption={imgCaption} />
    </>
  )
}

// Seo.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
// }

// Seo.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
// }

export default Seo;
