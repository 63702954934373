import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby"

const SchemaOrg = ({ homeURL, currentURL, titlePage, pageImage, imgCaption}) => {

    const imgUrls = useStaticQuery(
        graphql`
          query {
            siteLogo: file(relativePath: {eq: "NavLogo.png"}) {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        `
      );

      
      const {src: logoSrc} = imgUrls.siteLogo.childImageSharp.fluid;//logo
      
      

    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": ["Organization", "Place"],
            "@id": `${homeURL}#organization`,
            name: `Laboratorio Clínico G&S`,//CAMBIAR
            url: `${homeURL}`,
            logo: {
              "@id": `${currentURL}#local-main-organization-logo`
            },
            image: {
              "@id": `${currentURL}#primaryimage`
            },
            address: {
              "@id": `${currentURL}#local-main-place-address`
            },
            geo: {
              "@type": "GeoCoordinates",
              latitude: `13.717994489038889`,
              longitude: `-89.21448093002721`
            },
            telephone: [`2520 2947`],
            openingHoursSpecification: [
              {
                "@type": `OpeningHoursSpecification`,
                dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Friday"],
                opens: `07:00`,
                closes: `12:00`,
                opens: `13:00`,
                closes: `16:00`
              },
              {
                "@type": 'OpeningHoursSpecification',
                dayOfWeek: ["Thursday"],
                opens: '07:00',
                closes: '12:00',
                opens: '14:00',
                closes: '16:00'
              },
              {
                "@type": `OpeningHoursSpecification`,
                dayOfWeek: ["Saturday"],
                opens: '07:00',
                closes: '12:00'
              },
              {
                "@type": `OpeningHoursSpecification`,
                dayOfWeek: ["Sunday"],
                opens: `00:00`,
                closes: `00:00`
              }
            ],
            email: `info@laboratorioclinico.sv`,
            areaServed: `El Salvador` 
          },
          {
            "@type": "WebSite",
            "@id": `${homeURL}#website`,
            url: `${homeURL}`,
            name: `Laboratorio G&S`,
            description: `Somos una empresa, donde realizamos todo tipo de Análisis Clínicos, realizados por licenciados con mas de 10 años de experiencia, responsables, con el mejor servicio al cliente, contamos con parqueo para visitantes.`, ///CAMBIAR
            publisher: {
              "@id": `${homeURL}#organization`
            },           
            inLanguage: `en-sv`
          },
          {
            "@type":"ImageObject",
            "@id": `${currentURL}#primaryimage`,
            inLanguage: `en-US`,
            url: `${homeURL}${pageImage}`,
            contentUrl: `${homeURL}${pageImage}`,
            width: 1200,
            height: 900,
            caption: `${imgCaption}`
          },          
          {
            "@type":"WebPage",
             "@id": `${currentURL}#webpage`,
             url:`${currentURL}`,
             name: `${titlePage}`, 
             isPartOf: {
               "@id": `${homeURL}#website`   
             },       
            //  primaryImageOfPage: {
            //    "@id": `${currentURL}#primaryimage`
            //  },             
             description: `Somos una empresa, donde realizamos todo tipo de Análisis Clínicos, realizados por licenciados con mas de 10 años de experiencia, responsables, con el mejor servicio al cliente, contamos con parqueo para visitantes.`,//CAMBIAR
             breadcrumb: {"@id": `${currentURL}#breadcrumb`},
             inLanguage: `es-SV`,
             potentialAction:[{"@type": "ReadAction", target: [`${currentURL}`]}]             
          },
          {
            "@type": "BreadcrumbList",
            "@id": `${currentURL}#breadcrumb`,
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: `${homeURL}`
              },
              {
                "@type": "ListItem",
                position: 2,
                name: `${titlePage}`, //////EDIT               
              }
            ]
          },
          {
            "@type": "PostalAddress",
            "@id": `${currentURL}#local-main-place-address`,
            streetAddress: `Avenida 2 bulevar Universitario, Condominio Villas de San Luis`,
            addressLocality: `San Salvador`,
            postalCode: `06015`,////DUDA
            // addressRegion: `ON`,///DUDA
            addressCountry: `SV`
          },
          {
            "@type": "ImageObject",
            "@id": `${currentURL}#local-main-organization-logo`,
            inLanguage: `es-sv`,
            url: `${homeURL}${logoSrc}`,
            contentUrl:`${homeURL}${logoSrc}`,
            width: 1200,
            height: 726,
            caption: `laboratorio-clinico-g-s-logo`
          }
        ]
      }

    return ( 
        <>
            <Helmet>
              <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
            </Helmet>
        </>
     );
}
 
export default SchemaOrg;